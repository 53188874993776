@tailwind components;
@tailwind utilities;
@tailwind base;

@font-face {
  font-family: 'Geist';
  font-style: normal;
  font-weight: 100 900;
  src:
    local('Geist'),
    url('/fonts/Geist.var.woff2') format('woff2-variations');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src:
    local('Poppins'),
    url('/fonts/Poppins.woff2') format('woff2');
  font-display: swap;
}

body {
  background-color: theme(colors.gray.100);
  line-height: 175%;
  font-family: 'Geist', var(--system-fonts);
  font-size: var(--text-base);
  scrollbar-gutter: stable both-edges;
  overflow: overlay;

  transition:
    background-color 0.5s,
    color 0.5s,
    fill 0.5s,
    border 0.5s;
}

button:focus-visible {
  outline: 1px solid theme(colors.secondary.500);
  outline-offset: 5px;
}

a {
  text-decoration: underline;
  color: theme(colors.gray.800);
  cursor: pointer;
}

a:focus,
a:hover {
  color: theme(colors.primary.600);
  transition: color 150ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

::selection {
  color: theme(colors.primary.300);
  background-color: theme(colors.gray.800);
}

:root.dark body {
  color: theme(colors.gray.100);
  background-color: theme(colors.gray.900);
}

:where(:root.dark) a {
  color: theme(colors.gray.200);
}

:root.dark {
  color-scheme: dark;

  /* React Spring Bottom Sheet */
  --rsbs-handle-bg: hsla(0, 0%, 100%, 0.14);
  --rsbs-bg: theme(colors.gray.900);
}

/* Fix react-remove-scroll layout shift on Windows and friends */
@supports (scrollbar-gutter: stable) {
  :root body[data-scroll-locked] {
    margin: 0 !important;
  }
}

:root {
  --system-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* Minor second (1.067), base 16px */
  --text-sm: 0.771rem;
  --text-base: 0.937rem;
  --text-lg: 1.138rem;
  --text-xl: 1.215rem;
  --text-2xl: 1.296rem;
  --text-3xl: 1.383rem;
  --text-4xl: 1.476rem;
  overflow: auto;

  /* React Spring Bottom Sheet */
  --rsbs-bg: theme(colors.gray.100);
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);

  /* Header */
  --header-height: 42px;

  reach-portal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (min-width: theme(screens.md)) {
  :root {
    /* Minor third (⪆1.2), base 16px */
    --text-sm: 0.83rem;
    --text-base: 1rem;
    --text-lg: 1.2rem;
    --text-xl: 1.44rem;
    --text-2xl: 1.73rem;
    --text-3xl: 2.07rem;
    --text-4xl: 2.49rem;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', var(--system-fonts);
  line-height: 130%;
}

h1 {
  font-size: var(--text-4xl);
}

h2 {
  font-size: var(--text-3xl);
}

h3 {
  font-size: var(--text-2xl);
}

h4 {
  font-size: var(--text-xl);
}

h5 {
  font-size: var(--text-lg);
}

@layer components {
  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .shadow-dialog {
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  }

  .shadow-dropdown-menu {
    box-shadow:
      hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) -5px -10px 25px -15px;
  }

  .spinner {
    aspect-ratio: 1;
    width: 1.5rem;
    animation: spin 1s linear infinite;
    border-radius: 9999px;
    animation-duration: 1000ms;
    background-color: theme(colors.secondary.500);
    padding: 3px;
    -webkit-mask-composite: source-out;
    -webkit-mask:
      conic-gradient(#0000 10%, #000),
      linear-gradient(#000 0 0) content-box;
    mask-composite: subtract;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
