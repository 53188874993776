*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
}

::before,
::after {
  --tw-content: '';
}

html {
  /* Prevent adjustments of font size after orientation changes in iOS. */
  -webkit-text-size-adjust: 100%;
}

ol,
ul {
  list-style: none;
}

img,
video {
  max-width: 100%;
  height: auto;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}
